import {Modal} from "../modal/modal";
import React, {useEffect} from "react";
import "./how_to_button.css";
import {i18n} from "../../App";
import {get_google_forms_link, get_region_from_domain} from "../../utils/get_region_from_domain";

export type Region = "US" | "UK" | "TR" | "GB"

const region_timezone_map = new Map<Region, string>([
	["US", "America/New_York"],
	["UK", "Europe/London"],
	["TR", "Europe/Istanbul"],
	["GB", "Europe/London"]
]);

export function get_today(region: Region): Date {
	const locale_date_string = new Date().toLocaleString("en-US", {timeZone: region_timezone_map.get(region)});
	return (new Date(locale_date_string));
}

export function HowToButton() {
	const [show, setShow] = React.useState(false);
	const [back_counter, set_back_counter] = React.useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			set_back_counter(back_counter_for_next_day());
		}, 1000);
		return () => clearInterval(timer);
	}, []);

	const link = get_google_forms_link();

	function open_link_blank() {
		window.open(link, "_blank");
	}

	return (
		<>
			<Modal show={show} onClose={() => setShow(false)}>
				<h4>{i18n.t("goal_title")}</h4>
				<p>
					{i18n.t("goal_description")}
				</p>
				<h4 style={{}}>{i18n.t("remaining_time_to_next_day")}</h4>
				<h2>
					{milliseconds_to_time_string(back_counter)}
				</h2>

				<div className="feedback-button" onClick={open_link_blank}>
					{i18n.t("feedback")}
				</div>
			</Modal>

			<div onClick={() => setShow(true)} className="button">
				?
			</div>
		</>
	);
}

function back_counter_for_next_day() {
	const now = get_today(get_region_from_domain());
	const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
	return tomorrow.getTime() - now.getTime();
}

function milliseconds_to_time_string(milliseconds: number) {
	const seconds = Math.floor(milliseconds / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	return `${(hours % 24).toString().padStart(2, "0")}:${(minutes % 60).toString().padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
}
