import React, {memo, useLayoutEffect} from "react";
import {Letter} from "../../game_logic";
import "./letter.css";

type LetterProps = {
	letter: Letter,
	// delay: number,
	onSelected?: (letter: Letter) => void | undefined
	isSelected?: boolean
}

const created = new Set();

function LetterBoxComponent(props: LetterProps): JSX.Element {
	const [animation, set_animation] = React.useState("");
	const [animation2, set_animation2] = React.useState("appear");
	const letter = props.letter;
	const first = !created.has(letter.id);
	const duration = first ? 750 : 200;
	useLayoutEffect(() => {
		animation2 && setTimeout(() => {
			set_animation2("");
		}, 1000);

	}, []);
	created.add(letter.id);

	return (<div className={"letter-container " + animation + " " + animation2 + (letter.has_star ? " star" : "")}
				 style={{
					 gridColumn: letter.col,
					 gridRow: letter.row,
					 backgroundImage: "linear-gradient(" + get_heat_color(letter.level / 8) + "," + get_heat_color(letter.level / 8) + ")",
					 borderLeftColor: get_heat_color(letter.level / 8),
					 borderRightColor: get_heat_color(letter.level / 8),
					 borderTopColor: get_heat_color(letter.level / 8),
					 borderBottomColor: get_dark_heat_color(letter.level / 8),
					 animationDuration: duration + "ms",
					 opacity: props.isSelected ? 1 : undefined
				 }}
				 onTouchStart={handle_click}
				 onMouseDown={handle_click}>
		<div className="letter" style={{}}>
			{letter.value}
			<small className="points">
				{letter.level * 5 + (letter.has_star ? 17 : 0)}
			</small>
		</div>
	</div>);

	function handle_click(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) {
		// e.preventDefault();
		if (!props.isSelected) {
			set_animation("go-up");
			setTimeout(() => {
				props.onSelected && props.onSelected(letter);
			}, 500)
			;
		} else {
			set_animation("go-down");
			setTimeout(() => {
				props.onSelected && props.onSelected(letter);
			}, 200)
			;
		}

	}
}

function get_heat_color(value: number) {
	const h = (1.0 - value) * 240;
	return "hsla(" + h + ", 40%, 40%, .75)";
}

function get_dark_heat_color(value: number) {
	const h = (1.0 - value) * 240;
	return "hsla(" + h + ", 40%, 30%, 1)";
}

export const LetterBox = memo(LetterBoxComponent);
