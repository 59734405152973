import {Region} from "../components/how_to_button/how_to_button";

const us_flag_font_code = "u";
// const de_flag = "🇩🇪";
const tr_flag_font_code = "T";
const en_flag_font_code = "e";

const tr_flag_unicode = "🇹🇷";
const us_flag_unicode = "🇺🇸";
const en_flag_unicode = "🇬🇧";

export const GOOGLE_FORMS_MAP = new Map<string, string>([[
	"US", "https://forms.gle/KhXJL9RDbSM1yRjDA"],
	["EN", "https://forms.gle/ukP4i6iRLsaNdFJa6"],
	["TR", "https://forms.gle/kWfchGxg9htcDmyZ7"]
]);

const DOMAIN_MAP = new Map<string, [string, string, string]>([
	["https://wordybeat.acikbilim.com", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["https://wordybeat.acikbilim.com/", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["https://wordybeat.acikbilim.com/test", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["https://wordybeat.com", ["US", us_flag_font_code, us_flag_unicode]],
	["https://wordybeat.com/", ["US", us_flag_font_code, us_flag_unicode]],
	["https://wordybeat.com/test", ["US", us_flag_font_code, us_flag_unicode]],
	["https://www.wordybeat.com", ["US", us_flag_font_code, us_flag_unicode]],
	["https://www.wordybeat.com/", ["US", us_flag_font_code, us_flag_unicode]],
	["https://www.wordybeat.com/test", ["US", us_flag_font_code, us_flag_unicode]],
	["https://www.wordybeat.co.uk", ["UK", en_flag_font_code, en_flag_unicode]],
	["https://www.wordybeat.co.uk/", ["UK", en_flag_font_code, en_flag_unicode]],
	["https://www.wordybeat.co.uk/test", ["UK", en_flag_font_code, en_flag_unicode]],
	["https://wordybeat.co.uk", ["UK", en_flag_font_code, en_flag_unicode]],
	["https://wordybeat.co.uk/", ["UK", en_flag_font_code, en_flag_unicode]],
	["https://wordybeat.co.uk/test", ["UK", en_flag_font_code, en_flag_unicode]],
	["https://tr.wordybeat.com", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["https://tr.wordybeat.com/", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["https://tr.wordybeat.com/test", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["https://us.wordybeat.com", ["US", us_flag_font_code, us_flag_unicode]],
	["https://us.wordybeat.com/", ["US", us_flag_font_code, us_flag_unicode]],
	["https://us.wordybeat.com/test", ["US", us_flag_font_code, us_flag_unicode]]
	// ["https://de.wordybeat.com", ["DE", de_flag]],
]);
const DEV_DOMAIN_MAP = new Map<string, [string, string, string]>([
	// ["http://de.localhost:3000/", ["DE", de_flag]],
	["http://localhost:3000/", ["US", us_flag_font_code, us_flag_unicode]],
	["http://localhost:3000/test", ["US", us_flag_font_code, us_flag_unicode]],
	["http://tr.localhost:3000/", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["http://tr.localhost:3000/test", ["TR", tr_flag_font_code, tr_flag_unicode]],
	["http://en.localhost:3000/", ["US", us_flag_font_code, us_flag_unicode]],
	["http://en.localhost:3000/test", ["US", us_flag_font_code, us_flag_unicode]],
	["http://uk.localhost:3000/", ["UK", en_flag_font_code, en_flag_unicode]],
	["http://uk.localhost:3000/test", ["UK", en_flag_font_code, en_flag_unicode]],
	["http://us.localhost:3000/", ["US", us_flag_font_code, us_flag_unicode]],
	["http://us.localhost:3000/test", ["US", us_flag_font_code, us_flag_unicode]]
]);

function get_domain() {
	return process.env.NODE_ENV === "production" ? DOMAIN_MAP : DEV_DOMAIN_MAP;
}

export function get_google_forms_link() {
	return GOOGLE_FORMS_MAP.get(get_region_from_domain());
}

export function get_region_from_domain(): Region {
	if (typeof window === "undefined") {
		return "US";
	}
	const url = window.location.href as string;
	const domain = get_domain();
	const region = domain.get(url)?.[0] as Region;
	return region || "US";
}

export function get_flag_font_code_from_domain(): string {
	if (typeof window === "undefined") {
		return us_flag_font_code;
	}
	const url = window.location.href as string;
	const domain = get_domain();
	const flag = domain.get(url)?.[1];
	return flag || us_flag_font_code;
}

export function get_domain_from_region(region: string): string {
	const domain = get_domain();
	const url = Array.from(domain.entries()).find(([, [r, x]]) => r === region)?.[0];
	return url || "https://wordybeat.com";
}

export function get_distinct_elements_from_domain_map() {
	const v: Record<string, string> = {};
	const domain = get_domain();
	Array.from(domain.entries()).map(([key, value]) => value).forEach((value) => {
		v[value[0]] = value[1];
	});
	return v;

}

export function get_flag_unicode_from_domain(): string {
	if (typeof window === "undefined") {
		return us_flag_unicode;
	}
	const url = window.location.href as string;
	const domain = get_domain();
	const flag = domain.get(url)?.[2];
	return flag || us_flag_unicode;
}
