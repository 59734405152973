import React from "react";
import "./go_button.css";
import {i18n} from "../../App";

type GoButtonProps = {
	onClick: () => void;
	text? : string;
}
export function GoButton(props: GoButtonProps) : JSX.Element
{

	const [is_loading, set_is_loading] = React.useState(false);

	async function handleClick() {
		if (!props.onClick)
		{
			return;
		}
		if (is_loading)
		{
			return;
		}

		set_is_loading(true);
		await props.onClick();
		setTimeout(()=>{
			set_is_loading(false);
		},600);
	}

	return (<div className={"go-button" + (is_loading? " wait": "")} onClick={handleClick}>
		{is_loading? "..." : (props.text || i18n.t("go"))}
	</div>);
}
