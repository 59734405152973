import {I18n} from "i18n-js";
import React, {useCallback, useEffect, useState, useTransition} from "react";
import {FacebookIcon, TwitterIcon} from "react-share";
import "./App.css";
import {GoButton} from "./components/go_button";
import {HowToButton} from "./components/how_to_button/how_to_button";
import {LetterBox} from "./components/letter_box";
import {Modal} from "./components/modal/modal";
import {GameLogic, Letter} from "./game_logic";
import de from "./translations/de.json";
import en from "./translations/en.json";
import tr from "./translations/tr.json";
import {get_distinct_elements_from_domain_map, get_domain_from_region, get_flag_font_code_from_domain, get_flag_unicode_from_domain, get_region_from_domain} from "./utils/get_region_from_domain";

const REFRESH_HI_SCORES_INTERVAL = 1000 * 60; // 1 minute

export const i18n = new I18n({
	en: en,
	tr: tr,
	de: de,
	US: en,
	TR: tr,
	DE: de
}, {
	defaultLocale: "en",
	locale: "en"
});

enum WordStatus {
	VALID = "VALID",
	NOT_VALID = "NOT_VALID",
	SERVICE_ERROR = "SERVICE_ERROR",
	LOGIC_ERROR = "LOGIC_ERROR"
}

function App() {
	const [is_test_mode, set_is_test_mode] = useState(false);
	const [error_message, set_error_message] = React.useState("");
	const [language_list_visibility, set_language_list_visibility] = React.useState(false);
	const [region_flags, set_region_flags] = React.useState<Record<string, string>>({});
	const [is_translations_loaded, set_is_translations_loaded] = React.useState(false);
	const [gameLogic] = React.useState(new GameLogic());

	const [letters, setLetters] = React.useState<Letter[]>(gameLogic.get_letters());

	const [selectedLetters, setSelectedLetters] = React.useState<Letter[]>([]);

	const [selected_score, setSelectedScore] = React.useState(0);
	const [game_end, set_game_end] = React.useState(false);

	const [score, set_score] = React.useState(0);

	const [is_pending, start_transition] = useTransition();
	const [high_score, set_high_score] = React.useState<{ score: number, average_score: number }>();

	const [shake, setShake] = React.useState(false);

	const [score_change, set_score_change_animation] = React.useState(false);
	const [initialized, setInitialized] = React.useState<boolean>(false);

	const [dark_mode, set_dark_mode] = React.useState(localStorage.getItem("dark_mode") !== null
		? !!localStorage.getItem("dark_mode")
		: (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches));

	useEffect(() => {
		if (!localStorage.getItem("dark_mode")) {
			localStorage.setItem("dark_mode", "");
		}
		document.documentElement.setAttribute("data-theme", dark_mode ? "dark" : "light");
		const locale = get_region_from_domain();
		if (i18n.translations[locale]) {
			i18n.locale = locale;
		}
		set_is_translations_loaded(true);
		set_region_flags(get_distinct_elements_from_domain_map());
		if (window.location.href.includes("test")) {
			set_is_test_mode(true);
		}
	}, []);

	useEffect(() => {
		if (!initialized || !gameLogic) {
			gameLogic.init().then(() => {
				gameLogic.get_hi_score().then((hi_score) => {
					start_transition(() => {
						set_high_score(hi_score);
					});
					setInterval(() => {
						gameLogic.get_hi_score().then((hi_score) => {
							start_transition(() => {
								set_high_score(hi_score);
							});
						});
					}, REFRESH_HI_SCORES_INTERVAL);
				});
				start_transition(() => {
					setLetters(gameLogic.get_letters()); // todo
					setSelectedLetters(gameLogic.get_selected_letters());//todo
					setInitialized(true);
				});

			});
		}

	}, [gameLogic, initialized]);

	const redraw = useCallback((s?: boolean) => {
		start_transition(() => {
			setLetters(gameLogic.get_letters());
			setSelectedLetters(gameLogic.get_selected_letters());
			setSelectedScore(gameLogic.get_selected_score());
			if (s) {
				update_score();
			}
		});
	}, [gameLogic]);

	const update_score = useCallback(() => {
		start_transition(() => {
			set_score_change_animation(true);
			set_score(gameLogic.get_score());
			set_score_change_animation(true);
			setTimeout(() => {
				set_score_change_animation(false);
			}, 800);
		});
	}, [gameLogic]);

	const handleSelectLetterClick = useCallback((letter: Letter) => {
		gameLogic.select_letter(letter);
		redraw(false);
	}, [gameLogic, redraw]);

	const handleDeselectLetterClick = useCallback((letter: Letter) => {
		gameLogic.deselect_letter(letter);
		redraw(false);
	}, [gameLogic, redraw]);

	const handleGoClick = useCallback(async () => {
		const entered_word_status = await gameLogic.go();
		if (entered_word_status.status === WordStatus.NOT_VALID) {
			setShake(true);
			setTimeout(() => {
				setShake(false);
			}, 2000);
		}
		if (entered_word_status.status !== WordStatus.VALID && !!entered_word_status?.error_message) {
			set_error_message(entered_word_status?.error_message);
			setTimeout(() => {
				set_error_message("");
			}, 3000);
		}
		start_transition(() => {
			redraw(true);
		});
		if (gameLogic.get_is_game_finished()) {
			if (+(high_score || 0) <= score) {
				got_high_score();
			} else {
				game_done();
			}
			set_game_end(true);
		}
	}, [gameLogic, redraw, score, high_score]);

	function toggle_dark_mode() {
		localStorage.setItem("dark_mode", !dark_mode ? "1" : "");
		document.documentElement.setAttribute("data-theme", !dark_mode ? "dark" : "light");
		set_dark_mode(!dark_mode);
	}

	function share_on_twitter() {
		const url = encodeURIComponent(window?.location?.href || "https://www.wordybeat.com");
		const text = encodeURIComponent(`${get_flag_unicode_from_domain()} ${i18n.t("twitter_share_title", {point: gameLogic.get_score()})}`);
		const hastags = ["wordybeat", "game", "today"].join(",");
		window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hastags}`);

	}

	function share_on_facebook() {
		const url = encodeURIComponent(window?.location?.href || "https://www.wordybeat.com");
		const quote = encodeURIComponent(`${get_flag_font_code_from_domain()} ${i18n.t("twitter_share_title", {point: gameLogic.get_score()})}`);
		window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${quote}`);
	}

	return (
		<div className={"app"} onClick={() => !!language_list_visibility && set_language_list_visibility(false)}>
			<div>
				<Modal show={game_end}>
					<div className="end">
						<span>{i18n.t("you_got")}</span>
						<h1 style={{
							marginBlockEnd: "unset",
							marginBlockStart: "unset"
						}}>{gameLogic.get_score()}</h1>
						<span>{i18n.t("points")}</span>
						<h2>{i18n.t("share")}</h2>
						<div className={"social"}>
							<button
								style={{
									backgroundColor: "transparent",
									border: "none"
								}}
								onClick={share_on_twitter}>
								<TwitterIcon size={52} round={true}/>
							</button>
							<button
								style={{
									backgroundColor: "transparent",
									border: "none"
								}}
								onClick={share_on_facebook}>
								<FacebookIcon size={52} round={true}/>
							</button>
						</div>
						{(!!high_score && high_score?.average_score > 0) &&
                            <div style={{
								display: "flex",
								gap: "10px",
								alignItems: "flex-end",
								marginTop: "20px",
								marginBottom: "20px"
							}}>
                                <span>{i18n.t("today_average")}</span>
                                <span>{high_score?.average_score}</span>
                            </div>}
						<h4>{i18n.t("play_again")}</h4>
						<GoButton onClick={() => window.location.reload()}/>

					</div>
				</Modal>
			</div>

			<header>
				<div className="header-left">
					<HowToButton/>
					<div onClick={toggle_dark_mode} className="toggle-mode-button">
						{dark_mode ? "☼" : "☽"}
					</div>
				</div>
				<div className="logo-container">
					<div className="logo">
						<img
							src={document.documentElement.getAttribute("data-theme") === "dark" ? "/wb_dark.png" : "/wb_light.png"}
							alt="Wordybeat"/>
					</div>
				</div>

				<div className="header-right">
					{!!region_flags && <div style={{
						display: "flex",
						cursor: "pointer"
					}}>
                        <div className="flag"
                             onClick={() => set_language_list_visibility(!language_list_visibility)}>
							{get_flag_font_code_from_domain()}
                        </div>
						{!!language_list_visibility && <div className="language-list">
							{
								Object.keys(region_flags).map((region) => {
									return <span className="flag"

												 onClick={() => {
													 const domain = get_domain_from_region(region);
													 if (domain) {
														 window.location.replace(domain);
													 }
												 }}
									>{region_flags[region]}</span>;
								})
							}
                        </div>
						}
                    </div>
					}

				</div>
			</header>
			<div style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: 0
			}}>
				<div style={{
					fontSize: "0.9rem",
					display: "flex",
					alignItems: "center",
					gap: "0.1rem",
					fill: "var(--text-color)"
				}}>
					<svg height="24" viewBox="0 0 24 24" width="24"
						 xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.26756439,4 C5.61337381,3.40219863 6.25971764,3 7,3 L17,3 C17.7402824,3 18.3866262,3.40219863 18.7324356,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9 C22,12.2228413 20.4954134,14.5738734 17.7813405,14.9478456 C16.698759,16.5301579 14.9834979,17.645394 13,17.9291111 L13,19 L14,19 C15.1045695,19 16,19.8954305 16,21 L8,21 C8,19.8954305 8.8954305,19 10,19 L11,19 L11,17.9291111 C9.01650213,17.645394 7.30124102,16.5301579 6.21865948,14.9478456 C3.50458657,14.5738734 2,12.2228413 2,9 L2,6 C2,4.8954305 2.8954305,4 4,4 L5.26756439,4 Z M5,6 L4,6 L4,9 C4,10.5312611 4.39027687,11.6746449 5.1278274,12.3384891 C5.04393827,11.9052466 5,11.4577545 5,11 L5,6 Z M19,6 L19,11 C19,11.4577545 18.9560617,11.9052466 18.8721726,12.3384891 C19.6097231,11.6746449 20,10.5312611 20,9 L20,6 L19,6 Z M7,5 L7,11 C7,13.7614237 9.23857625,16 12,16 C14.7614237,16 17,13.7614237 17,11 L17,5 L7,5 Z"
							fillRule="evenodd"/>
					</svg>
					{i18n.t("highest_score_today")}: {high_score?.score || "..."}
				</div>
				<div className={"score " + (score_change ? "score-change" : "")}>
					{i18n.t("score")}: {score}
				</div>
			</div>

			<div className={"word-stack-container "}>
				<div className={"word-stack"}>
					{
						!!shake && <div className="not-valid shake">
							{i18n.t("not_valid")}
                        </div>
					}
					{
						!shake && selectedLetters.map((letter, index) =>
							(
								<LetterBox key={letter.id} letter={letter}
										   isSelected={selectedLetters.some(l => l.id === letter.id)}
										   onSelected={handleDeselectLetterClick}/>
							))
					}
					<div className="pts">{selected_score}</div>
				</div>
			</div>

			<div className="game-grid">
				{letters.map((letter) =>
					(
						<LetterBox key={letter.id}
								   letter={letter}
								   isSelected={selectedLetters.some(l => l.id === letter.id)}
								   onSelected={handleSelectLetterClick}/>
					)
				)}
			</div>
			<div style={{
				flex: 1,
				flexDirection: "column",
				paddingTop: "0.2rem"
			}}>
				<div className="tries-left">
					{gameLogic.get_number_of_tries_left()[0]} {gameLogic.get_number_of_tries_left()[0] > 1 ? i18n.t("tries") : i18n.t("try")} {i18n.t("left")}
				</div>
				<GoButton onClick={handleGoClick}/>
				{!!error_message && <div style={{
					marginTop: "0.5rem",
					fontSize: "1rem",
					color: "red"
				}}>
					{error_message}
                </div>}
			</div>

		</div>
	);
}

declare var confetti: (arg0: { origin: { y: number; }; } & { particleCount: number; }) => void;

function game_done() {
	const count = 250;
	const defaults = {
		origin: {y: 0.7}
	};

	function fire(particleRatio: number, opts: unknown) {
		confetti(Object.assign({}, defaults, opts, {
			particleCount: Math.floor(count * particleRatio)
		}));
	}

	fire(0.25, {
		spread: 26,
		startVelocity: 55
	});
	fire(0.2, {
		spread: 60
	});
	fire(0.35, {
		spread: 100,
		decay: 0.91,
		scalar: 0.8
	});
	fire(0.1, {
		spread: 120,
		startVelocity: 25,
		decay: 0.92,
		scalar: 1.2
	});
	fire(0.1, {
		spread: 120,
		startVelocity: 45
	});
}

function got_high_score() {
	const duration = 15 * 1000;
	const animationEnd = Date.now() + duration;
	const defaults = {
		startVelocity: 30,
		spread: 360,
		ticks: 60,
		zIndex: 0
	};

	function randomInRange(min: number, max: number) {
		return Math.random() * (max - min) + min;
	}

	let interval: NodeJS.Timer;
	interval = setInterval(() => {
		const timeLeft = animationEnd - Date.now();

		if (timeLeft <= 0) {
			return clearInterval(interval);
		}

		const particleCount = 50 * (timeLeft / duration);
		// since particles fall down, start a bit higher than random
		confetti(Object.assign({}, defaults, {
			particleCount,
			origin: {
				x: randomInRange(0.1, 0.3),
				y: Math.random() - 0.2
			}
		}));
		confetti(Object.assign({}, defaults, {
			particleCount,
			origin: {
				x: randomInRange(0.7, 0.9),
				y: Math.random() - 0.2
			}
		}));
	}, 250);
}

export default App;
