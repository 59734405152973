// const api_base = "https://worders-backend-functions.azurewebsites.net/api/";
//const api_base = "http://192.168.1.86:7071/api/";
// const api_base = "http://localhost:7071/api/";

import {get_region_from_domain} from "../utils/get_region_from_domain";

const api_base = process.env.NODE_ENV === "development" ?
	process.env.REACT_APP_API_BASE_URL :
	"https://worders-backend-functions.azurewebsites.net/api/";

type StartData = {
	session_id: string,
	starting_letters: Letter[],
	number_of_tries_left: number,
	next_request_id: string
};

enum WordStatus {
	VALID = "VALID",
	NOT_VALID = "NOT_VALID",
	SERVICE_ERROR = "SERVICE_ERROR",
	LOGIC_ERROR = "LOGIC_ERROR"
}

export class GameLogic {
	private _letters: Letter[] = new Array(25).fill(0).map((l, i) => ({
		id: i + 9000,
		value: "⌛",
		level: Math.ceil(Math.min(i / 3, 8)),
		col: ((i + 1) % 5),
		row: (Math.ceil((i + 1) / 5)),
		has_star: false
	}));

	private _selected_letters: Letter[] = [];
	private readonly _number_of_letters = 25;
	private readonly _number_of_tries = 0;

	private _number_of_tries_left = this._number_of_tries;
	private _score = 0;
	private _session_id = "";
	private _init_lock = false;

	private _is_game_finished = false;
	private _next_request_id = "";

	private _is_init = false;

	async init() {

		// Check it is pre-render
		if (window && window.location.port === "45678") {
			return;
		}
		if (this._init_lock) {
			return;
		}
		this._init_lock = true;
		if (this._session_id) {
			return;
		}
		const start_response = await fetch(api_base + "get-new-session?region=" + get_region_from_domain());
		const start_data: StartData = await start_response.json();
		this._letters = this.map_letters([], start_data.starting_letters);
		this._number_of_tries_left = start_data.number_of_tries_left;
		this._next_request_id = start_data.next_request_id;
		this._session_id = start_data.session_id;
		this._is_init = true;
	}

	get_score() {
		return this._score;
	}

	get_selected_score() {
		return this._selected_letters.reduce((acc, l) => acc + l.level * 5 + (l.has_star ? 17 : 0), 0);
	}

	get_number_of_tries_left() {
		return [this._number_of_tries_left, this._number_of_tries];
	}

	get_letters(): Letter[] {
		return this._letters;
	}

	get_selected_letters(): Letter[] {
		return this._selected_letters;
	}

	async get_hi_score() {
		if (window && window.location.port === "45678") {
			return;
		}

		const response = await fetch(api_base + "get-hi-score?region=" + get_region_from_domain());
		if (!response.ok) {
			return {
				score: 0,
				average_score: 0
			};
		}
		const data = await response.json();

		return {
			score: data?.score || 0,
			average_score: data?.average_score || 0
		};
	}

	select_letter(clicked_letter: Letter) {

		if (!this._is_init) {
			return;
		}

		const letter = this._letters.find(l => l.id === clicked_letter.id);
		if (letter) {
			this._selected_letters.push(letter);
			this._letters = this._letters.filter(l => l.id !== clicked_letter.id);
		}
	}

	deselect_letter(clicked_letter: Letter) {
		const letter = this._selected_letters.find(l => l.id === clicked_letter.id);
		if (letter) {
			this._letters.push(letter);
			this._selected_letters = this._selected_letters.filter(l => l.id !== clicked_letter.id);
		}
	}

	async go(): Promise<{ status: WordStatus, error_message?: string }> {
		if (!this._is_init) {
			return {
				status: WordStatus
					.LOGIC_ERROR,
				error_message: "Game is not initialized"
			};
		}

		if (this._selected_letters.length < 1 || this._number_of_tries_left <= 0) {
			return {
				status: WordStatus
					.VALID,
				error_message: "No letters selected"
			};
		}
		const word_ids = this._selected_letters.map(l => l.id).join("-");

		let retry_left = 3;
		let response: Response | null = null;
		while (retry_left > 0) {
			try {
				response = await fetch(`${api_base}get-next?session_id=${this._session_id}&word=${word_ids}&request_id=${this._next_request_id}`);
				break;
			} catch (e) {
				await delay(1000);
				retry_left--;
			}
		}

		if (!response || !response.ok) {
			return {
				status: WordStatus
					.SERVICE_ERROR,
				error_message: (await response?.json())?.message || "Service error"
			};
		}

		const next_data = await response.json();

		if (!next_data.is_valid) {
			// this._selected_letters.forEach(l => this.deselect_letter(l));
			return {
				status: WordStatus
					.NOT_VALID

			};
		}
		this._score = next_data.score;
		this._number_of_tries_left = next_data.number_of_tries_left;
		this._letters = this.map_letters(this._letters, next_data.letters);
		this._selected_letters = [];
		this._next_request_id = next_data.next_request_id;

		if (next_data.is_finished) {
			this._is_game_finished = true;
			this._letters = [];
			this._selected_letters = [];
		}
		return {
			status: WordStatus
				.VALID
		};
	}

	get_is_game_finished() {
		return this._is_game_finished;
	}

	private map_letters(old_letters: Letter[], letters: Letter[]): Letter[] {
		const rows = 5;
		const cols = 5;
		const new_letters_queue = [...letters.filter(x => !old_letters.some(y => y.id === x.id))];
		let old_letters_queue = [...old_letters.filter(x => letters.some(y => y.id === x.id))];
		const new_letters: Letter[] = [];
		for (let c = 1; c < cols + 1; c++) {
			for (let r = 1; r < rows + 1; r++) {

				const old = old_letters_queue.find(l => l.row === r && l.col === c);
				if (old) {
					new_letters.push(old);
					old_letters_queue = old_letters_queue.filter(l => l.id !== old.id);
					continue;
				}
				const new_letter = new_letters_queue.shift();
				if (new_letter) {
					new_letter.row = r;
					new_letter.col = c;
					new_letters.push(new_letter);
				}

			}
		}
		return new_letters;
	}

}

async function delay(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export type Letter = {
	value: string;
	level: number;
	id?: number;
	col: number;
	row: number;
	has_star: boolean;
}
