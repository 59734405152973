import "./modal.css";

type ModalProps = {
	children: React.ReactNode,
	onClose?: ()=>void
	show: boolean
}

export function Modal(props:ModalProps)
{

	return (<div className="backdrop" style={{display: props.show? "flex": "none"}} onClick={()=> props?.onClose ? props.onClose() : null}>

		<div className="modal_container">
			{props.children}
		</div>

	</div>)


}
